import React, { useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../../AppContext";
import { Flex } from "../SwapBoard";
import { isMobile } from "react-device-detect";
import { ShowMoreIcon } from "../Icons";
import useClickOutside from "../../hooks/useClickOutside";

export const NAV_HEIGHT = "100px";

export const Text = styled.p`
    margin-left: 8px;
    font-family: Changa One;
    font-size: 80px;
    line-height: 80px;
    font-style: normal;
    font-weight: 400;
    background: linear-gradient(86.2deg, #59F6BB 43.72%, #C2FFE7 92.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-transform: uppercase;
    /* text-shadow: 0px 6px 0px #00643F; */
    ::before {
      text-shadow: #000 6px 6px 0px;
      content: 'market';
      position: absolute;
      top: 0px;
      left: -3px;
      z-index: -1;
    }
    :after {
      text-shadow: #000 6px 6px 4px;
    content: 'market';
    position: absolute;
    top: -6px;
    left: -6px;
    z-index: -1;
}
    
    @media screen and (max-width: 1024px) {
      font-size: 28px;
    }
`;

const NavWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  background: url('/images/market/nav.png');
  filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.25));
  z-index: 1000;
  height: ${NAV_HEIGHT};
  @media screen and (max-width: 768px) {
    height: 60px;
  }
`;

const StyledLogoIcon = styled.img`
  width: ${({ width }) => width ?? "100%"};
  height: auto;
  margin-right: 8px;
  width: 280px;
  height: 100px;
  @media screen and (max-width: 768px) {
    width: 180px;
    height: 70px;
  }
`;

const StyledLink = styled.div`
  transition: 0.2 ease-out;
  color: #000;
  font-weight: 700;
  ${({ active }) => active && `cursor:default;`}
  text-decoration: unset;
  position: relative;
  text-align: center;
  :hover {
    opacity: 0.8;
    color: #000;
  }
`;

const StyledMenuButton = styled(ShowMoreIcon)`
  cursor: pointer;
`;

const NavLinkWrapper = styled.div`
  top: -10%;
  opacity: 0;
  z-index: -1;
  ${({ isShow }) =>
    isShow && `top: calc(100% + 12px);opacity:1;z-index:1;padding:1rem;`}
  position: absolute;
  background: #fff;
  box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  flex-direction: column;
  left: -200%;
  width: 200px;
  transform: translateX(-50%);
  & > * + * {
    margin-top: 1rem;
  }
  @media screen and (min-width: 768px) {
    opacity: 1;
    flex-direction: row;
    box-shadow: unset;
    background: transparent;
    border: unset;
    border-radius: 0;
    z-index: 1;
    position: unset;
    justify-content: center;
    width: auto;
    transform: unset;
    * {
      margin-top: 0;
    }
  }
`;

const StyledAddress = styled.div`
  padding: 8px 1rem;
  font-family: 'Changa One';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: #FFFFFF;

  text-shadow: 3px 0 0 #009760, -3px 0 0 #009760, 0 3px 0 #009760, 0 -3px 0 #009760, 2px 2px #009760, -2px -2px 0 #009760, 2px -2px 0 #009760, -2px 2px 0 #009760;
`;

const ButtonConnect = styled.div`
  cursor: pointer;
  background: url('/images/market/btn-connect.png');
  background-repeat: no-repeat;
  width: 254px;
  height: 100px;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 36px;
  line-height: 38px;
  letter-spacing: 0.02em;
  align-items: center;
  color: #FFFFFF;
  font-family: 'Changa One';
  text-shadow: 3px 0 0 #009760, -3px 0 0 #009760, 0 3px 0 #009760, 0 -3px 0 #009760, 2px 2px #009760, -2px -2px 0 #009760, 2px -2px 0 #009760, -2px 2px 0 #009760;
  @media screen and (max-width: 768px) {
    transform: scale(0.8);
  }
`

const RenderConnectButton = (
  account,
  handleConnect,
  openModalHandler,
) => {
  if (window.ethereum) {
    if (!account) {
      return (
        <ButtonConnect onClick={handleConnect}>Connect</ButtonConnect>
      );
    }
  } else {
    if (isMobile)
      return (
        <ButtonConnect
          style={{ textDecoration: "none" }}
          target="_blank"
          as="a"
          href={process.env.REACT_APP_METAMASK_DOMAIN}
        >
          Connect
        </ButtonConnect>
      );
    return (
      <ButtonConnect
        as="a"
        target="_blank"
        href="https://metamask.io/download.html"
      >
        Install Metamask
      </ButtonConnect>
    );
  }

  if (account)
    return (
      <ButtonConnect>
        <StyledAddress onClick={openModalHandler}>
          {account.slice(0, 5)}...{account.slice(-5)}
        </StyledAddress>
      </ButtonConnect>
    );
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Navbar = ({ account, networkId, openModalHandler, myRefCode }) => {
  const { handleConnect, switchNetworkHandler } = useContext(AppContext);
  const { pathname } = useLocation();
  const [isShowLink, setShowLink] = React.useState(false);

  const navRef = useRef(null);
  useClickOutside(navRef, () => {
    if (isShowLink) setShowLink(false);
  });

  const query = useQuery();

  return (
    <NavWrapper>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className="h-100 position-relative"
      >
        <Flex
          alignItems="center"
          as={Link}
          to="/"
          style={{ textDecoration: "unset" }}
        >
          <StyledLogoIcon src="/image.png" width="100px" />
        </Flex>

        <div
          className="position-relative mx-md-auto "
          style={{ marginRight: 5 }}
          ref={navRef}
        >
          <StyledMenuButton
            width="25px"
            onClick={() => setShowLink((prev) => !prev)}
            className="d-md-none"
          />

          <NavLinkWrapper
            className="d-flex align-items-center"
            isShow={isShowLink}
          >
            <StyledLink
              as={Link}
              to={
                query.get("ref") ? `/market?ref=${query.get("ref")}` : "/market"
              }
              className="text-center"
              active={pathname.includes("market")}
            >
              {/* <Text
                fontSize="20px"
                fontWeight={500}
                className="ms-0"
                color="inherit"
                active={pathname.includes("market")}
                text="Market"
              >
                Market
              </Text> */}
              <img  style={{filter: !pathname.includes("market") && 'grayscale(100%)', width: "100%"}}  src="/images/market/nav/market.png" />
            </StyledLink>

            {account && (
              <StyledLink
                className={`ms-1 ms-md-5 text-center`}
                as={Link}
                to={
                  query.get("ref")
                    ? `/my-nft?ref=${query.get("ref")}`
                    : "/my-nft"
                }
                active={pathname.includes("my-nft")}
              >
                {/* <Text
                  fontSize="20px"
                  fontWeight={500}
                  className="ms-0"
                  color="inherit"
                  textAlign="center"
                  active={pathname.includes("my-nft")}
                >
                  My NFT
                </Text> */}
                 <img style={{ filter: !pathname.includes("my-nft") && 'grayscale(100%)', width: "100%"}} src="/images/market/nav/mynft.png" />
              </StyledLink>
            )}
            {/* <a
              className={`ms-1 ms-md-5 text-center`}
              style={{ textDecoration: "none", color: "black" }}
              href="https://nftmarket.winerydao.day/upload/market-guide.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text
                fontSize="20px"
                fontWeight={500}
                className="ms-0"
                color="inherit"
                textAlign="center"
                active={pathname.includes("guide")}
              >
                Guide
              </Text>
            </a> */}
            <Flex
              alignItems="center"
              style={!isMobile ? { display: "none" } : {}}
            >
              {networkId != process.env.REACT_APP_CHAIN_ID && account ? (
                <ButtonConnect
                  onClick={() =>
                    switchNetworkHandler(process.env.REACT_APP_CHAIN_ID)
                  }
                >
                  Wrong network
                </ButtonConnect>
              ) : (
                RenderConnectButton(
                  account,
                  handleConnect,
                  openModalHandler,
                  myRefCode
                )
              )}
            </Flex>
          </NavLinkWrapper>
        </div>

        <Flex alignItems="center" style={isMobile ? { display: "none" } : {}}>
          {networkId != process.env.REACT_APP_CHAIN_ID && account ? (
            <ButtonConnect
              onClick={() =>
                switchNetworkHandler(process.env.REACT_APP_CHAIN_ID)
              }
            >
              Wrong network
            </ButtonConnect>
          ) : (
            RenderConnectButton(
              account,
              handleConnect,
              openModalHandler,
              myRefCode
            )
          )}
        </Flex>
      </Flex>
    </NavWrapper>
  );
};

export default Navbar;
