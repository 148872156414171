import React, {
  useContext,
  lazy,
  Suspense,
  useCallback,
  useState,
} from "react";
import "./App.css";
import GlobalStyled from "./GlobalStyled";
import { Routes, Route, Navigate } from "react-router-dom";
import { AppContext } from "./AppContext";
import styled from "styled-components";
import { AnimatePresence } from "framer-motion";

import Navbar from "./components/Navbar";
import AccountModal from "./components/Modal/AccountModal";

const Market = lazy(() => import("./components/Market"));
const MyNFT = lazy(() => import("./components/MyNFT"));
const Category = lazy(() => import("./components/Category"));

const Layout = styled.div`
  background: "#fafafa";
  //#f9ecd8;
  overflow: hidden;
  background: url("/images/market/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const Wrapper = styled.div`
  margin-top: 100px;
`;

function App() {
  const { account, networkId, myRefCode } = useContext(AppContext);
  const [isShowAcc, setShowAcc] = useState(false);

  const closeModalHandler = useCallback(() => setShowAcc(false), []);
  const openModalHandler = useCallback(() => setShowAcc(true), []);
  return (
    <Layout>
      <GlobalStyled />
      <Navbar
        account={account}
        networkId={networkId}
        openModalHandler={openModalHandler}
        myRefCode={myRefCode}
      />
      <AccountModal
        isShowAcc={isShowAcc}
        closeModalHandler={closeModalHandler}
      />
      <Wrapper>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Suspense fallback={<div />}>
            <Routes>
              <Route path="/" element={<Navigate replace to="/market" />} />
              <Route
                path="/market"
                element={<Market networkId={networkId} account={account} />}
              />
              <Route path="/my-nft" element={<MyNFT />} />

            </Routes>
          </Suspense>
        </AnimatePresence>
      </Wrapper>
    </Layout>
  );
}

export default App;