import React, { createContext, useState, useEffect } from "react";
import getWeb3, { getWeb3BSC } from "./getWeb3";

import useDAO from "./hooks/useDAO";
import Web3 from "web3";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const { getRefCode, getBalance, getListMyNFT } = useDAO();

  const [web3, setWeb3] = useState();
  const [web3Bsc, setWeb3Bsc] = useState();
  const [account, setAccount] = useState(null);
  const [networkId, setNetworkId] = useState(null);
  const [tokenSwap, setTokenSwap] = useState(null);
  const [tokenReceive, setTokenReceive] = useState(null);
  const [hasAccountChanged, setHasAccountChanged] = useState(false);
  const [balance, setBalance] = useState({});
  const [myRefCode, setMyRef] = useState(null);

  const [nftSell, setNftSell] = useState([]);

  const [listMyNft, setListMyNft] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleConnect = async () => {
    await window.ethereum.enable();
  };

  const handleLogout = () => {
    setAccount(null);
  };

  const switchNetworkHandler = async (chainId) => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${Number(process.env.REACT_APP_CHAIN_ID).toString(16)}`,
            rpcUrls: [process.env.REACT_APP_BSC],
            chainName: process.env.REACT_APP_CHAIN_NAME,
            nativeCurrency: {
              name: "BNB",
              symbol: "BNB", // 2-6 characters long
              decimals: 18,
            },
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getDefaultBalance = async (web3bsc, account) => {
    const data = await getBalance(web3bsc, account);
    setBalance(data || 0);
  };

  const loadOptionSell = async () => {
    setIsLoading(true);

    setIsLoading(false);
  };

  const loadMyNFT = async () => {
    const newWeb3 = new Web3(process.env.REACT_APP_BSC);
    const data = await getListMyNFT(account, newWeb3);

    setListMyNft(data);
  };

  useEffect(() => {
    const init = async () => {
      if (window.ethereum) {
        const [web3eth, web3bsc] = await Promise.allSettled([
          getWeb3(),
          getWeb3BSC(),
        ]);
        const [networkId, accounts] = await Promise.allSettled([
          web3eth.value.eth.net.getId(),
          web3eth.value.eth.getAccounts(),
        ]);

        setWeb3(web3eth.value);
        setWeb3Bsc(web3bsc.value);
        setNetworkId(networkId.value);

        if (accounts.value.length > 0) {
          setAccount(accounts.value[0]);
          await getDefaultBalance(web3bsc.value, accounts.value[0]);

          const refCode = await getRefCode(accounts.value[0]);
          setMyRef(refCode);
        }
        window.ethereum.on("accountsChanged", async (accounts) => {
          setHasAccountChanged(true);
          if (!accounts[0]) {
            setAccount(null);
            setTokenSwap(null);
            setTokenReceive(null);
          } else {
            setTokenSwap(null);
            setTokenReceive(null);
            setAccount(web3eth.value.utils.toChecksumAddress(accounts[0]));
            await getDefaultBalance(web3bsc.value, accounts[0]);
            const refCode = await getRefCode(accounts[0]);
            setMyRef(refCode);
          }
        });
        window.ethereum.on("chainChanged", (_chainId) =>
          window.location.reload()
        );
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadOptionSell();
    if (account) {
      loadMyNFT();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <AppContext.Provider
      value={{
        account,
        handleConnect,
        handleLogout,
        switchNetworkHandler,
        networkId,
        tokenReceive,
        tokenSwap,
        web3,
        web3Bsc,
        hasAccountChanged,
        balance,
        getDefaultBalance,
        myRefCode,
        nftSell,
        isLoading,
        setNftSell,
        listMyNft,
        loadMyNFT,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
