import React from "react";

import Web3 from "web3";
import nftABI from "../contracts/NFT.json";
import launchPadABI from "../contracts/LaunchPad.json";
import IERC20ABI from "../contracts/IERC20.json";

import axios from "axios";

import useMultiCall from "./useMultiCall";

import { Interface } from "@ethersproject/abi";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const multicall = async (multi, abi, calls) => {
  const itf = new Interface(abi);

  const calldata = calls.map((call) => [
    call.address.toLowerCase(),
    itf.encodeFunctionData(call.name, call.params),
  ]);
  const { returnData } = await multi.methods.aggregate(calldata).call();
  const res = returnData.map((call, i) =>
    itf.decodeFunctionResult(calls[i].name, call)
  );

  return res;
};

const NFT_ADDRESS = process.env.REACT_APP_NFT_ADDRESS;
const LAUNCHPAD_ADDRESS = process.env.REACT_APP_LAUNCHPAD_ADDRESS;
const BUSD_TOKEN_ADDRESS = process.env.REACT_APP_BUSD_TOKEN_ADDRESS;

const useMarketHook = () => {
  const { getMultiContract } = useMultiCall();

  const getBalance = async (web3, account) => {
    if (!web3) {
      return [];
    }

    try {
      const contract = new web3.eth.Contract(IERC20ABI, BUSD_TOKEN_ADDRESS);

      const balance = await contract.methods.balanceOf(account).call();

      return balance / 1e18;
    } catch (e) {
      console.error("getBalance: ", e);
    }
  };

  const getListMyNFT = async (account, web3) => {
    const ADDRESS = NFT_ADDRESS;

    if (!account || !web3) {
      return [];
    }
    try {
      const multicallContract = getMultiContract(web3);

      const contract = new web3.eth.Contract(nftABI, ADDRESS);

      const balances = Number(await contract.methods.balanceOf(account).call());

      let tokenIds = [];
      let tokenInfos = [];

      if (balances == "0") {
        return [];
      }

      const calls = [];

      for (let index = 0; index < balances; index++) {
        calls.push({
          address: ADDRESS,
          name: "tokenOfOwnerByIndex",
          params: [account, index],
        });
      }

      tokenIds = await multicall(multicallContract, nftABI, calls);
      tokenIds = tokenIds.map((id, index) => id.toString());

      if (tokenIds.length) {
        const calls = [];
        tokenIds.forEach((id) => {
          calls.push({
            address: ADDRESS,
            name: "getToken",
            params: [+id],
          });
        });
        tokenInfos = await multicall(multicallContract, nftABI, calls);

        tokenInfos = tokenInfos.map((item) => ({
          createTimestamp: item.createTimestamp.toNumber(),
          tokenId: item.tokenId.toNumber(),
          tokenOwner: item.tokenOwner,
          uri:
            `${
              process.env.REACT_APP_API_DOMAIN
            }/nft/metadata/${item.tokenId.toNumber()}` ?? item.uri,
        }));
        return tokenInfos;
      }
    } catch (e) {
      console.log("getListMyNFT: ", e);
      return [];
    }
  };

  const buyNFT = async ({ price, launchId, account, web3 }) => {
    try {
      const contract = new web3.eth.Contract(launchPadABI, LAUNCHPAD_ADDRESS);
      const data = await contract.methods
        .buyNFT(launchId, process.env.REACT_APP_BUSD_TOKEN_ADDRESS)
        .send({ from: account });

      await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/transactions`,
        {
          address: account,
          type: "market",
          amount: +price,
          launchpadId: launchId,
          txHash: data.transactionHash,
          tokenId: +data.events.Buy.returnValues.nftId,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": true,
          },
        }
      );

      return true;
    } catch (e) {
      console.log("getListMyNFT: ", e);
      const error = e.message;

      if (error.includes("insufficient funds")) {
        withReactContent(Swal).fire({
          imageUrl: "/error-face.png",
          imageWidth: "auto",
          imageHeight: "auto",
          imageAlt: "Custom image",

          title: <span style={{ color: "#ED1C51" }}> Failed...</span>,
          textColor: "red",
          html: (
            <span style={{ color: "rgb(128, 128, 128)", fontWeight: 400 }}>
              Insufficient BNB
            </span>
          ),
          focusConfirm: false,
          confirmButtonText: "Continue",

          backdrop: `#e7edf599`,
        });
      }
      return false;
    }
  };

  const getRefCode = async (account) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/users?address=${account}`,
        {
          headers: {
            "Access-Control-Allow-Origin": true,
          },
        }
      );

      return response.data.data;
    } catch (e) {
      console.log("getRefCode: ", e);
    }
  };

  const getListLaunchPad = async ({
    page = 1,
    limit = 30,
    price,
    rank = "",
    numMin = 1,
    numMax = 75,
    sort,
    type,
    category,
  }) => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/nft`, {
        headers: {
          "Access-Control-Allow-Origin": true,
        },
        params: {
          page,
          limit,
          min: price.min,
          max: price.max,
          rank: rank == "ALL" ? null : rank,
          numMin,
          numMax,
          sort,
          type,
          category,
        },
      });

      return data.data;
    } catch (e) {
      console.log("getListLaunchPad", e);
      return null;
    }
  };

  const getDetailNft = async (launchId) => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/nft/metadata/${launchId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": true,
          },
        }
      );

      return { ...data.data };
    } catch (e) {
      console.log("getDetailNft", e);
    }
  };

  const getLaunchPadById = async (launchId) => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/nft/launch/${launchId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": true,
          },
        }
      );

      return data.data;
    } catch (e) {
      console.log("getListLaunchPad", e);
      return null;
    }
  };

  return {
    getRefCode,
    buyNFT,
    getListMyNFT,
    getBalance,
    getListLaunchPad,
    getDetailNft,
    getLaunchPadById,
  };
};

export default useMarketHook;
